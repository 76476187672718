define("discourse/plugins/discourse-math/initializers/discourse-math-katex", ["exports", "discourse/lib/plugin-api", "discourse/lib/load-script"], function (_exports, _pluginApi, _loadScript) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ensureKaTeX() {
    return (0, _loadScript.default)("/plugins/discourse-math/katex/katex.min.js").then(function () {
      return (0, _loadScript.default)("/plugins/discourse-math/katex/katex.min.css", {
        css: true
      }).then(function () {
        return (0, _loadScript.default)("/plugins/discourse-math/katex/mhchem.min.js");
      });
    });
  }

  function decorate(elem) {
    var $elem = $(elem);
    var displayMode = elem.tagName === "DIV";

    if ($elem.data("applied-katex")) {
      return;
    }

    $elem.data("applied-katex", true);

    if ($elem.hasClass("math")) {
      var tag = elem.tagName === "DIV" ? "div" : "span";
      var displayClass = tag === "div" ? "block-math" : "inline-math";
      var text = $elem.text();
      $elem.addClass("math-container ".concat(displayClass, " katex-math")).text("");
      window.katex.render(text, elem, {
        displayMode: displayMode
      });
    }
  }

  function katex($elem) {
    if (!$elem || !$elem.find) {
      return;
    }

    var mathElems = $elem.find(".math");

    if (mathElems.length > 0) {
      ensureKaTeX().then(function () {
        mathElems.each(function (idx, elem) {
          return decorate(elem);
        });
      });
    }
  }

  function initializeMath(api) {
    api.decorateCooked(function (elem) {
      katex(elem);
    }, {
      id: "katex"
    });
  }

  var _default = {
    name: "apply-math-katex",
    initialize: function initialize(container) {
      var siteSettings = container.lookup("site-settings:main");

      if (siteSettings.discourse_math_enabled && siteSettings.discourse_math_provider === "katex") {
        (0, _pluginApi.withPluginApi)("0.5", function (api) {
          initializeMath(api);
        });
      }
    }
  };
  _exports.default = _default;
});define("discourse/plugins/discourse-math/initializers/discourse-math-mathjax", ["exports", "discourse/lib/plugin-api", "discourse-common/lib/get-url", "discourse/lib/load-script"], function (_exports, _pluginApi, _getUrl, _loadScript) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var initializedMathJax = false;

  function initMathJax(opts) {
    if (initializedMathJax) {
      return;
    }

    var extensions = ["toMathML.js", "Safe.js"];

    if (opts.enable_accessibility) {
      extensions.push("[a11y]/accessibility-menu.js");
    }

    var settings = {
      jax: ["input/TeX", "input/AsciiMath", "input/MathML", "output/CommonHTML"],
      TeX: {
        extensions: ["AMSmath.js", "AMSsymbols.js", "autoload-all.js"]
      },
      extensions: extensions,
      showProcessingMessages: false,
      root: (0, _getUrl.getURLWithCDN)("/plugins/discourse-math/mathjax")
    };

    if (opts.zoom_on_hover) {
      settings.menuSettings = {
        zoom: "Hover"
      };
      settings.MathEvents = {
        hover: 750
      };
    }

    window.MathJax = settings;
    initializedMathJax = true;
  }

  function ensureMathJax(opts) {
    initMathJax(opts);
    return (0, _loadScript.default)("/plugins/discourse-math/mathjax/MathJax.2.7.5.js");
  }

  function decorate(elem, isPreview) {
    var $elem = $(elem);

    if ($elem.data("applied-mathjax")) {
      return;
    }

    $elem.data("applied-mathjax", true);
    var $mathWrapper, $math;

    if ($elem.hasClass("math")) {
      var tag = elem.tagName === "DIV" ? "div" : "span";
      var display = tag === "div" ? "; mode=display" : "";
      var displayClass = tag === "div" ? "block-math" : "inline-math";
      var type = "math/tex".concat(display);
      var classList = "math-container ".concat(displayClass, " mathjax-math");
      $mathWrapper = $("<".concat(tag, " class=\"").concat(classList, "\" style=\"display: none;\">\n         <script type=\"").concat(type, "\"></script>\n       </").concat(tag, ">"));
      $math = $mathWrapper.children();
      $math.text($elem.text());
      $elem.after($mathWrapper);
    } else if ($elem.hasClass("asciimath")) {
      // asciimath is always inline
      var _classList = "math-container inline-math ascii-math";
      var _type = "math/asciimath";
      $mathWrapper = $("<span class=\"".concat(_classList, "\" style=\"display: none;\">\n         <script type=\"").concat(_type, "\"></script>\n       </span>"));
      $math = $mathWrapper.children();
      $math.text($elem.text());
      $elem.after($mathWrapper);
    }

    Ember.run.later(this, function () {
      window.MathJax.Hub.Queue(function () {
        // don't bother processing previews removed from DOM
        if (elem.parentElement && elem.parentElement.offsetParent !== null) {
          window.MathJax.Hub.Typeset($math[0], function () {
            $elem.hide();
            $mathWrapper.show();
          });
        }
      });
    }, isPreview ? 200 : 0);
  }

  function mathjax($elem, opts) {
    if (!$elem || !$elem.find) {
      return;
    }

    var mathElems;

    if (opts.enable_asciimath) {
      mathElems = $elem.find(".math, .asciimath");
    } else {
      mathElems = $elem.find(".math");
    }

    if (mathElems.length > 0) {
      var isPreview = $elem.hasClass("d-editor-preview");
      ensureMathJax(opts).then(function () {
        mathElems.each(function (idx, elem) {
          return decorate(elem, isPreview);
        });
      });
    }
  }

  function initializeMath(api, discourse_math_opts) {
    api.decorateCooked(function (elem) {
      mathjax(elem, discourse_math_opts);
    }, {
      id: "mathjax"
    });
  }

  var _default = {
    name: "apply-math-mathjax",
    initialize: function initialize(container) {
      var siteSettings = container.lookup("site-settings:main");
      var discourse_math_opts = {
        zoom_on_hover: siteSettings.discourse_math_zoom_on_hover,
        enable_accessibility: siteSettings.discourse_math_enable_accessibility,
        enable_asciimath: siteSettings.discourse_math_enable_asciimath
      };

      if (siteSettings.discourse_math_enabled && siteSettings.discourse_math_provider === "mathjax") {
        (0, _pluginApi.withPluginApi)("0.5", function (api) {
          initializeMath(api, discourse_math_opts);
        });
      }
    }
  };
  _exports.default = _default;
});define("discourse/plugins/discourse-math/lib/discourse-markdown/discourse-math", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setup = setup;

  // inspired by https://github.com/classeur/markdown-it-mathjax/blob/master/markdown-it-mathjax.js
  //
  //
  //
  function isSafeBoundary(character_code, delimiter_code, md) {
    if (character_code === delimiter_code) {
      return false;
    }

    if (md.utils.isWhiteSpace(character_code)) {
      return true;
    }

    if (md.utils.isMdAsciiPunct(character_code)) {
      return true;
    }

    if (md.utils.isPunctChar(character_code)) {
      return true;
    }

    return false;
  }

  function math_input(state, silent, delimiter_code) {
    var pos = state.pos,
        posMax = state.posMax;

    if (silent || state.src.charCodeAt(pos) !== delimiter_code || posMax < pos + 2) {
      return false;
    } // too short


    if (state.src.charCodeAt(pos + 1) === delimiter_code) {
      return false;
    }

    if (pos > 0) {
      var prev = state.src.charCodeAt(pos - 1);

      if (!isSafeBoundary(prev, delimiter_code, state.md)) {
        return false;
      }
    }

    var found;

    for (var i = pos + 1; i < posMax; i++) {
      var code = state.src.charCodeAt(i);

      if (code === delimiter_code && state.src.charCodeAt(i - 1) !== 92
      /* \ */
      ) {
          found = i;
          break;
        }
    }

    if (!found) {
      return false;
    }

    if (found + 1 <= posMax) {
      var next = state.src.charCodeAt(found + 1);

      if (next && !isSafeBoundary(next, delimiter_code, state.md)) {
        return false;
      }
    }

    var data = state.src.slice(pos + 1, found);
    var token = state.push("html_raw", "", 0);
    var escaped = state.md.utils.escapeHtml(data);
    var math_class = delimiter_code === 36 ? "'math'" : "'asciimath'";
    token.content = "<span class=".concat(math_class, ">").concat(escaped, "</span>");
    state.pos = found + 1;
    return true;
  }

  function inlineMath(state, silent) {
    return math_input(state, silent, 36
    /* $ */
    );
  }

  function asciiMath(state, silent) {
    return math_input(state, silent, 37
    /* % */
    );
  }

  function isBlockMarker(state, start, max, md) {
    if (state.src.charCodeAt(start) !== 36
    /* $ */
    ) {
        return false;
      }

    start++;

    if (state.src.charCodeAt(start) !== 36
    /* $ */
    ) {
        return false;
      }

    start++; // ensure we only have newlines after our $$

    for (var i = start; i < max; i++) {
      if (!md.utils.isSpace(state.src.charCodeAt(i))) {
        return false;
      }
    }

    return true;
  }

  function blockMath(state, startLine, endLine, silent) {
    var start = state.bMarks[startLine] + state.tShift[startLine],
        max = state.eMarks[startLine];

    if (!isBlockMarker(state, start, max, state.md)) {
      return false;
    }

    if (silent) {
      return true;
    }

    var nextLine = startLine;
    var closed = false;

    for (;;) {
      nextLine++; // unclosed $$ is considered math

      if (nextLine >= endLine) {
        break;
      }

      if (isBlockMarker(state, state.bMarks[nextLine] + state.tShift[nextLine], state.eMarks[nextLine], state.md)) {
        closed = true;
        break;
      }
    }

    var token = state.push("html_raw", "", 0);
    var endContent = closed ? state.eMarks[nextLine - 1] : state.eMarks[nextLine];
    var content = state.src.slice(state.bMarks[startLine + 1] + state.tShift[startLine + 1], endContent);
    var escaped = state.md.utils.escapeHtml(content);
    token.content = "<div class='math'>\n".concat(escaped, "\n</div>\n");
    state.line = closed ? nextLine + 1 : nextLine;
    return true;
  }

  function setup(helper) {
    if (!helper.markdownIt) {
      return;
    }

    helper.registerOptions(function (opts, siteSettings) {
      opts.features.math = siteSettings.discourse_math_enabled;
      opts.features.asciimath = siteSettings.discourse_math_enable_asciimath;
    });
    helper.registerPlugin(function (md) {
      if (md.options.discourse.features.math) {
        if (md.options.discourse.features.asciimath) {
          md.inline.ruler.after("escape", "asciimath", asciiMath);
        }

        md.inline.ruler.after("escape", "math", inlineMath);
        md.block.ruler.after("code", "math", blockMath, {
          alt: ["paragraph", "reference", "blockquote", "list"]
        });
      }
    });
  }
});